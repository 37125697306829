import React from "react";
import { ContentContainer } from "../components/ContentContainer";
import Layout from "../components/Layout";

export default function CV(props: {}) {
  return (
    <Layout>
      <ContentContainer className="max-w-2xl">
        <header className="">
          <h1 className="text-5xl uppercase font-thin text-center antialiased">
            Curriculum Vitae
          </h1>
        </header>

        <section className="grid grid-cols-2 gap-4">
          <h2 className="col-span-2 text-3xl font-bold font-condensed uppercase text-center mt-8 mb-4">
            Arbetslivserfarenhet
          </h2>

          <div className="col-span-1">
            <h3>Friskis &amp; Svettis Stockholm</h3>
          </div>

          <div className="col-span-1">
            <p>Art Director</p>
            <p>2017 - Nuvarande</p>
          </div>
          <div className="col-span-1">
            <h3>Gigantprint AB</h3>
          </div>

          <div className="col-span-1">
            <p>Prepress och Originalare</p>
            <p>2010 - 2017</p>
          </div>

          <div className="col-span-1">
            <h3>Kårhuset Trappan</h3>
          </div>
          <div className="col-span-1">
            <p>Marknadsföringsgruppen</p>
            <p>2009 - 2010</p>
          </div>

          <div className="col-span-1">
            <h3>Svetruck AB</h3>
          </div>
          <div className="col-span-1">
            <p>VD- &amp; marknadsassistent</p>
            <p>2005 - 2008</p>
          </div>

          <h2 className="col-span-2 text-3xl font-bold font-condensed uppercase text-center mt-8 mb-4">
            Utbildning
          </h2>

          <div className="col-xs-12 col-sm-5 col-sm-offset-1 col-md-4 col-md-offset-3 cv__place">
            <h3>Grafisk design &amp; kommunikation</h3>
          </div>
          <div className="col-xs-12 col-sm-5 col-md-4 cv__position">
            <p>Kandidatexamen, Linköpings Universitet</p>
            <p>2008 - 2011</p>
          </div>

          <div className="col-xs-12 col-sm-5 col-sm-offset-1 col-md-4 col-md-offset-3 cv__place">
            <h3>Typografi &amp; grafisk design</h3>
          </div>
          <div className="col-xs-12 col-sm-5 col-md-4 cv__position">
            <p>Kurs, Malmö Högskola</p>
            <p>VT 2008</p>
          </div>

          <div className="col-xs-12 col-sm-5 col-sm-offset-1 col-md-4 col-md-offset-3 cv__place">
            <h3>English for Professional Purposes</h3>
          </div>
          <div className="col-xs-12 col-sm-5 col-md-4 cv__position">
            <p>Kurs, Malmö Högskola</p>
            <p>VT 2008</p>
          </div>

          <div className="col-xs-12 col-sm-5 col-sm-offset-1 col-md-4 col-md-offset-3 cv__place">
            <h3>Medieprogrammet/Medieproduktion</h3>
          </div>
          <div className="col-xs-12 col-sm-5 col-md-4 cv__position">
            <p>Sunnebogymnasiet</p>
            <p>2003 - 2005</p>
          </div>

          <div className="col-xs-12 col-sm-5 col-sm-offset-1 col-md-4 col-md-offset-3">
            <h2 className="col-span-2 text-xl font-bold font-condensed uppercase my-4">
              Avancerade kunskaper
            </h2>
            <ul className="cv__list">
              <li>Adobe Photoshop CC</li>
              <li>Adobe Indesign CC</li>
              <li>Adobe Illustrator CC</li>
              <li>Adobe Acrobat CC</li>
              <li>Enfocus Pitstop Pro</li>
              <li>Adobe Lightroom CC</li>
              <li>Microsoft Office</li>
            </ul>
          </div>

          <div className="col-xs-12 col-sm-5 col-md-4">
            <h2 className="col-span-2 text-xl font-bold font-condensed uppercase my-4">
              Språkkunskaper
            </h2>
            <ul className="cv__list">
              <li>Svenska - modersmål</li>
              <li>Engelska - flytande i tal och skrift</li>
            </ul>
          </div>
        </section>
      </ContentContainer>
    </Layout>
  );
}
